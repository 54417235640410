body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-control:disabled {
  background-color: rgb(245, 245, 245);
  opacity: 0.9;
}

.p-disabled {
  background-color: rgb(245, 245, 245); 
  opacity: 0.9;
}

.p-datatable {
  border-bottom: 1px solid #e9ecef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pi-angle-double-left,
.pi-angle-double-right,
.pi-angle-left,
.pi-angle-right {
  font-size: 24px;
}

.searchField:focus {
  border-color: #ccc;
}

.btn-search:hover {
  color: '#6c757d' !important;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.hideScrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.filterContainer {
  position: absolute;
  width: 450px;
  height: 0;
  border: solid 1px #ccc;
  border-radius: 4px;
  background: white;
  opacity: 0;
  overflow: hidden;
  z-index: 10000;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.swal2-styled.swal2-confirm {
  background: #FFC107 !important;
}

.swal2-styled.swal2-confirm:focus {
  border-width: 0 !important;
  box-shadow: 0 0 0 2px rgba(235, 189, 41, 0.5) !important;
}

.login-container {
  display: flex;
}

.bg-login {
  border: solid 0px red;
  display: inline-block;
  flex: 5;
}

.login-form {
  border: solid 0px blue;
  display: inline-block;
  flex: 2;
}

@media (max-width: 800px) {
  .login-container {
    flex-direction: column;
  }

  .bg-login {
    flex: 1;
    overflow: hidden;
  }

  .login-form {
    flex: 4;
  }
}

.inputLogin {
  width: 100%;
  border: none !important;
  padding: 10px;
  flex: 1;
}

.inputLogin:focus {
  border: none !important;
  border-width: 0;
}

.p-checkbox-icon {
  color: white !important;
}

.select2-selection__choice {
  background-color: #FFC107 !important;
  border-color: #FFC107 !important;
}

.select2-results__option--highlighted {
  background-color: #FFC107 !important;
}

.btn-warning {
  color: white !important;
}

.swal2-icon.swal2-question {
  border-color: #FFC107 !important;
  color: #FFC107 !important;
}