.App {
  text-align: center;
}

.requiredd:after {
  content:" *";
  color: red;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* for incoming add screen */
/* .p-dropdown{
  z-index: 9999 !important;
} */

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
  color: #06b6d4 !important;
}

a{
  color: gray;
/*  style dari frbt sampe order-2*/
}
.frbt {
  border-collapse: collapse;
  width: 100%;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}

.order-1 {
  order: 2;
}

.order-2 {
  order: 1;
}

.row-table-ppd{
  padding: 8px !important;
}

.action-center{
  text-align: center;
}